<template lang="pug">
.full-box
  DataTable.page-table(
    v-if="showTable"
    ref="recordTable"
    keyName="recordTable"
    serverSide
    :needFilterMargin="false"
    resource="/chargingRecon/list"
    :filterFields="filterFields"
    :columns="columns"
    :operateButtonList="operateButtonList"
    :operationWidth="80")
</template>

<script>
import { mapActions } from 'vuex'

// 银行对账
export default {
  name: 'ChargingRecon',
  data () {
    const actionRights = this.$getPageActions([
      { name: 'detail', url: '/billManage/chargingRecon/detail' }
    ])
    return {
      showTable: false,
      filterFields: [{
        name: 'reconTime',
        label: '对账日期',
        form: {
          tag: 'date',
          dateType: 'daterange'
        }
      }, {
        name: 'reconStatus',
        label: '对账状态',
        form: {
          tag: 'select',
          relation: 'reconStatus'
        }
      }],
      columns: [
        { name: 'reconTime', label: '对账日期', render: { type: 'date' } },
        { name: 'reconStatus', label: '对账状态', relation: 'reconStatus', render: { type: 'select' } },
        {
          name: 'billCount',
          label: '对账缴费账单数',
          width: 150,
          innerColumns: [{
            name: 'billCountReconBefore',
            label: '应对'
          }, {
            name: 'billCountReconAfter',
            label: '实对'
          }]
        },
        {
          name: 'billAmount',
          label: '对账缴费金额',
          width: 150,
          innerColumns: [{
            name: 'billAmountReconBefore',
            label: '应对'
          }, {
            name: 'billAmountReconAfter',
            label: '实对'
          }]
        },
        { name: 'name', label: '收款账户' },
        { name: 'accountNo', label: '收款账号' },
        { name: 'collectionNo', label: '商户号', minWidth: 130 },
        { name: 'terminalNo', label: '终端号' },
        { name: 'orgNo', label: '机构号' }
      ],
      operateButtonList: [{
        label: '查看',
        name: 'check',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.detail,
        func: ({ data }) => {
          this.$router.push({
            name: 'chargingReconDetail',
            query: {
              reconId: data.id
            }
          })
        }
      }]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['reconStatus'])
        .then(res => {
          this.$renderRelationColumns(res, [this.columns, this.filterFields])
          this.$nextTick(() => { this.showTable = true })
        })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.account-check
  display: flex
  flex-direction: column
  .table-box
    flex: 1
    display: flex
    flex-direction: column
    .blue-header
      margin-left: 8px
      margin-top: 4px
    .page-table
      flex: 1
  .gray-bar
    height: 8px
    background-color: $--content-backgroud
</style>
